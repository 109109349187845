<template>
	<div>
		<v-card>
			<v-toolbar flat color="white">
				<v-toolbar-title>
					<v-icon class='mb-1'>mdi-account-key</v-icon>
					PERMISOS
				</v-toolbar-title>
				<v-divider
					class="mx-4"
					inset
					vertical
				></v-divider>
				 
				<v-col cols="3">
				<v-text-field
					v-model="searchPermissionName"
					append-icon="mdi-magnify"
					label="Buscar"
					single-line
					class='mt-5'
					@keyup.enter="cargarLista()"
				></v-text-field>
				</v-col>
				<v-col cols="3">
				<v-btn 
					small
					color = 'primary'
					@click="crearPermiso"
				> 
					<v-icon>mdi-plus-circle-outline</v-icon> Nuevo permiso
				</v-btn>
				</v-col>
			</v-toolbar>
			<v-data-table
				:headers="headers"
				:items="lista"
				:items-per-page=itemsPerPage
				
				hide-default-footer
				:loading=loadingTable
				class="elevation-1"
			>
				<template v-slot:item.opcion="{item}">
					<v-btn 
						class="mx-4"
						icon 
						color='green' 						
						@click="editarPermiso(item)"
					>
						<v-icon  >mdi-pencil</v-icon>
					</v-btn>

					<v-btn 
						class="mx-4"
						icon 
						color='red' 						
						@click="eliminarPermiso(item.permission_id)"
					>
						<v-icon  >mdi-delete</v-icon>
					</v-btn>

				</template>
				
				<template v-slot:footer>        
                <v-pagination
                class="mt-10"
                v-model="currentPage"
                :length="pageCount"
                @input="handlePageChange"
                total-visible="10"
                ></v-pagination>
            </template>
			 
			</v-data-table>
		</v-card>
         

        <!-- dialogo permiso -->
		<v-dialog
			v-model="dialogPermiso"
			persistent
			max-width="500px"
		>	
			<v-card>
				<v-card-title>
					<span class="headline">Permiso</span>
					
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-form ref="formPermiso">
							<v-row>
								<v-col class="pt-0 pb-0" cols="12" >
									<v-text-field :rules="requiredRule" v-model="datosPermiso.permission_id" label="Código"></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="pt-0 pb-0" cols="12" >
									<v-text-field :rules="requiredRule" v-model="datosPermiso.description" label="Nombre"></v-text-field>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions >
					<v-col class='text-center'>
						<v-btn
							small
							class="mx-4"
							color="primary"
							@click="guardarPermiso"
						>
							<v-icon>mdi-content-save</v-icon> Guardar
						</v-btn>
						<v-btn
							small
							class="mx-4"
							@click="dialogPermiso = !dialogPermiso"
						>
							<v-icon>mdi-cancel</v-icon> Cancelar
						</v-btn>
					</v-col>
				</v-card-actions>
			</v-card>
		</v-dialog>	
		 
	</div>
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex'
import Vue from 'vue'

export default {
	name: "SecurityPermissionComponent",
	data: ()=> ({
    	headers: [
			{ text: 'Opciones', value: 'opcion' },
			{ text: 'Codigo', value: 'permission_id' },
			{ text: 'Nombre', value: 'description' },
        ],
		
		headPermisos: [
			{ text: 'Opciones', value: 'opcion' , align:"center", width:150},
			{ text: 'Permission Id', value:'permission_id' , align:"start"},
			{ text: 'Descripcion', value: 'description' , align:"start"},
		],
		
		requiredRule:[
            v => !!v || 'El campo es requerido'
        ],
		lista :[],
		options: {},
		currentPage:1,
		pageCount:0,
		totalItems: 0,
		dialog: false,
		notifications: false,
		sound: true,
		widgets: true,
		permissionId:null,
		securityPermissionId:null,		
		//tenantIdPermission:null,
		searchPermissionName:null,
		searchPermissionGroup:null,
		searchUsers:null,
		tentantId:null,
		users:null,
		//securityPermissions:[],
		//permisos: [],
		//usuarios: [],
		//usuariosActivos: [],
		//formUsuario: false,
		//permisosDisp : [],
		dialogPermiso: false,
		itemsPerPage: 20,
		searchGroupId: "",
		searchNombreGrupo: "",
		
		//sgGroupId:null,
		//sgGroupName:null,
		//formSecurityGroup: false,
		spEdit:false,
		datosPermiso: {permission_id: "", description:""}
    }),
    computed: {

    	...mapState('master',['loadingTable','user','tenantId','companies']),
    	
    	...mapGetters('access', ['btnAbrirCaja'])

    },
    methods: {

    	...mapMutations('master',['setUrl','setOverlay','setMenu','setLoadingTable']),
	   	 
	    ...mapActions('master',['requestApi','alertNotification']),

		crearPermiso(){
    		this.spEdit = false
    		this.datosPermiso.permission_id = "";
    		this.datosPermiso.description = "";
			this.dialogPermiso = true
		},
		
		editarPermiso(item){
			this.datosPermiso.permission_id = item.permission_id
			this.datosPermiso.description =  item.description
			this.spEdit = true
			this.dialogPermiso = true
		},
		guardarPermiso(){
			
			if(this.spEdit == false) {
				if(!this.$refs.formPermiso.validate()){
					return false
				}
	            this.setUrl('security-permission')
	            this.requestApi({
	                method : 'POST',
	                data : {
						"accion" : "crearPermiso",
				       	"permission_id":   this.datosPermiso.permission_id,
				       	"description": this.datosPermiso.description
				    }
	            }).then(res=>{
	
	            	console.log(res);			        			    		
	            	this.cargarLista()							    	
				
	            }).catch(()=>{
		              
	            }).then(()=>{
	            	this.dialogPermiso = false
				})
			} 
			if(this.spEdit == true ) {
				if(!this.$refs.formPermiso.validate()){
					return false
				}
				this.setUrl('security-permission' )
	            this.requestApi({
	                method : 'POST',
	                data : {			     
						"accion" : "modificarPermiso",  	
						"permission_id" : this.datosPermiso.permission_id,
				       	"description": this.datosPermiso.description
				    }
	            }).then(res=>{

	            	console.log(res);			        			    		
	            	this.cargarLista()							    	
				
	            }).catch(()=>{
		              
	            }).then(()=>{
	            	this.dialogPermiso = false
				})
				
				
			}
			
		},
		
		
	     
        cargarLista() {
			
			this.setLoadingTable(true)
            this.setUrl('security-permission')
            this.requestApi({
                method : 'GET',
                data : {
					page: this.currentPage,
                    page_size: this.itemsPerPage,
					permissionName: this.searchPermissionName
			    }
            }).then(res=>{

            	//console.log(res);
            	this.lista = res.data._embedded.security_permission;
            	this.pageCount = res.data.page_count;			
				this.totalItems = res.data.total_items
				//this.currentPage = res.data.page				    	
			
            }).catch(()=>{
	              
            }).then(()=>{
				this.setLoadingTable(false)
			})
        	  
        },

        /*abrirFormSecurityPermission(spid) {
        
            this.setUrl('security-permission/'+spid)
            this.requestApi({
                method : 'GET',
                data : {}
            }).then(res=>{
				//console.log(res.data)
            	this.securityPermissionId = spid
				this.datosPermiso = res.data.securityPermission
				//this.permisos = res.data.permissions;
				//this.usuarios = res.data.users;	
				//this.securityPermissions = res.data.securityPermissions		
				//this.usuariosActivos =res.data.loginUsers	
				this.dialogPermiso = true

            }).then(()=>{
	              
            })
            
        },*/

		 

		eliminarPermiso(permissionId){

			Vue.swal({
            html: "Está seguro de eliminar el permiso?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Aceptar',
            cancelButtonText: 'Cerrar',
            ...this.paramAlertQuestion
            }).then( result => {
                if (result.isConfirmed) {

					this.setUrl('security-permission')
					this.requestApi({
						method : 'POST',
						data : {
							permission_id: permissionId,
							accion:'borrarPermiso'
						}
					}).then(res=>{
																	
						this.alertNotification({param:{html: res.data.detail}})
						/*let obj = this.permisos.find(e => e.permission_id == permissionId)
						let index = this.permisos.indexOf(obj)
						this.permisos.splice(index,1)*/

					})
					
				}
				
			});

		},

         

		eliminarUsuario(userLoginId,tentantId){

			Vue.swal({
				html: "Está seguro de quitar al usuairo del grupo de permiso?",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Aceptar',
				cancelButtonText: 'Cerrar',
				...this.paramAlertQuestion
            }).then( result => {
                if (result.isConfirmed) {

					this.setUrl('user-security-group')
					this.requestApi({
						method : 'DELETE',
						data : {
							securityGroupPermissionId: this.securityGroupId,
							tentantId:tentantId,
							userLoginId: userLoginId,
							typeDelete:'eliminarUsuario'
						}
					}).then(res=>{
																	
						this.alertNotification({param:{html: res.data.detail}})
						let obj = this.usuarios.find(e => e.user_login_id == userLoginId && e.tenant_id == tentantId)
						let index = this.usuarios.indexOf(obj)
						this.usuarios.splice(index,1)

					})
					
				}
				
			});

		},

		handlePageChange(value) {
            this.cargarLista()
        },
        
    },
	mounted(){
		this.cargarLista()
	}
    
  }

</script>